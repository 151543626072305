import { scrollbar } from 'style/global.styles';
import { css } from '@emotion/react';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';
import { breakpoints } from 'style/variables';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins/spacing';

export const root = css`
  padding: calc(var(--unit) * 20) 0;
  background: hsl(var(--color-grayscale-10));

  @media ${breakpoints.large} {
    padding: calc(var(--unit) * 30) 0 calc(var(--unit) * 20);
  }
`;

export const title = css`
  margin-bottom: calc(var(--unit) * 20);

  @media ${breakpoints.large} {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h1 {
      max-width: ${spacing(640)};
    }
  }
`;

export const info = css`
  display: flex;
  max-width: ${spacing(416)};
  align-items: center;
  margin-top: calc(var(--unit) * 10);

  @media ${breakpoints.large} {
    margin-top: 0;
  }
`;

export const infoDescription = css`
  ${font.size.small};
  color: hsl(var(--color-textLight-7));
  padding-left: calc(var(--unit) * 10);
  border-left: 1px solid hsl(var(--color-borderBlack-2));
`;

export const infoNumber = css`
  padding-right: calc(var(--unit) * 10);
  text-align: center;

  p:first-of-type {
    ${font.size.large}
    margin-bottom: 2px;
  }

  p:last-of-type {
    ${font.size.tiny};
    color: hsl(var(--color-textLight-7));
    text-transform: uppercase;
  }
`;

export const matches = css`
  display: flex;
  overflow-x: auto;
  max-width: 100%;
  margin-top: calc(var(--unit) * 15);

  ${scrollbar};
`;

export const opening = css`
  margin-right: calc(var(--unit) * 5);
  min-width: ${spacing(416)};
  max-width: ${spacing(416)};

  &:last-of-type {
    margin-right: 0;
  }

  @media ${breakpoints.smallMax} {
    max-width: ${spacing(280)};
    min-width: ${spacing(280)};
  }

  @media ${breakpoints.large} {
    margin-right: calc(var(--unit) * 8);
  }
`;
